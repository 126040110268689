<div class="content">
    <mat-toolbar class="app-toolbar mat-elevation-z1">
        <!-- <app-application-toolbar-content /> -->
        @defer (on immediate;) {
        <app-application-toolbar-content />
        } @placeholder {
        <div></div>
        }
    </mat-toolbar>

    @defer (on idle;) {
    <app-main />
    } @placeholder (){
    <div style="width:100%;height:100vh;"></div>
    }

</div>
<footer class="footer">
    @defer (on viewport;) {
    <app-footer></app-footer>
    } @placeholder {
    <div></div>
    }
</footer>
