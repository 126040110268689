import { inject } from "@angular/core";
import { AuthTokenProviderService } from "../services/auth-token-provider.service";

/**
 * Function to provide the access token.
 * @returns The access token or null if no access token is available as Observable. 
 */
export function provideAccessToken(): Promise<string | null> {
    const authTokenProviderService = inject(AuthTokenProviderService);
    // console.log ('authTokenProviderService', authTokenProviderService  )
    return authTokenProviderService.getAccessToken();
}



