/* eslint-disable @typescript-eslint/no-explicit-any */
import { DOCUMENT } from '@angular/common';
import {
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
} from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { AUTH_JWT_OPTIONS } from '../models/auth-jwt-options.token';
import { AuthJwtHelperService } from '../services/auth-jwt-helper.service';

import { Observable, defer, of } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { AuthTokenProviderService } from '../services/auth-token-provider.service';

const fromPromiseOrValue = <T>(input: T | Promise<T>) => {
    if (input instanceof Promise) {
        return defer(() => input);
    }
    return of(input);
};
@Injectable()
export class AuthJwtInterceptor implements HttpInterceptor {
    tokenGetter: (
        request?: HttpRequest<any>
    ) => string | null | Promise<string | null>;
    headerName: string;
    authScheme: string | ((request?: HttpRequest<any>) => string);
    allowedDomains: Array<string | RegExp>;
    disallowedRoutes: Array<string | RegExp>;
    throwNoTokenError: boolean;
    skipWhenExpired: boolean;
    standardPorts: string[] = ['80', '443'];



    constructor(
        @Inject(AUTH_JWT_OPTIONS) config: any,
        public jwtHelper: AuthJwtHelperService,
        private authTokenProviderService: AuthTokenProviderService,
        @Inject(DOCUMENT) private document: Document
    ) {
        this.tokenGetter = config.tokenGetter;
        this.headerName = config.headerName || 'Authorization';
        this.authScheme =
            config.authScheme || config.authScheme === ''
                ? config.authScheme
                : 'Bearer ';
        this.allowedDomains = config.allowedDomains || [];
        this.disallowedRoutes = config.disallowedRoutes || [];
        this.throwNoTokenError = config.throwNoTokenError || false;
        this.skipWhenExpired = config.skipWhenExpired;
    }

    isAllowedDomain(request: HttpRequest<any>): boolean {
        const requestUrl: URL = new URL(request.url, this.document.location.origin);

        // If the host equals the current window origin,
        // the domain is allowed by default
        if (requestUrl.host === this.document.location.host) {
            return true;
        }

        // If not the current domain, check the allowed list
        const hostName = `${requestUrl.hostname}${requestUrl.port && !this.standardPorts.includes(requestUrl.port)
            ? ':' + requestUrl.port
            : ''
            }`;

        return (
            this.allowedDomains.findIndex((domain) =>
                typeof domain === 'string'
                    ? domain === hostName
                    : domain instanceof RegExp
                        ? domain.test(hostName)
                        : false
            ) > -1
        );
    }

    isDisallowedRoute(request: HttpRequest<any>): boolean {
        const requestedUrl: URL = new URL(
            request.url,
            this.document.location.origin
        );

        return (
            this.disallowedRoutes.findIndex((route: string | RegExp) => {
                if (typeof route === 'string') {
                    const parsedRoute: URL = new URL(
                        route,
                        this.document.location.origin
                    );
                    return (
                        parsedRoute.hostname === requestedUrl.hostname &&
                        parsedRoute.pathname === requestedUrl.pathname
                    );
                }

                if (route instanceof RegExp) {
                    return route.test(request.url);
                }

                return false;
            }) > -1
        );
    }


    handleInterception(
        token: string | null,
        request: HttpRequest<any>,
        next: HttpHandler
    ) {
        const authScheme = this.jwtHelper.getAuthScheme(this.authScheme, request);

        if (!token && this.throwNoTokenError) {
            //console.log ('no token');
            throw new Error('Could not get token from tokenGetter function.');
        }

        let tokenIsExpired = of(false);

        if (this.skipWhenExpired) {
            tokenIsExpired = token ? fromPromiseOrValue(this.jwtHelper.isTokenExpired(token)) : of(true);
        }

        if (token) {
            //console.log('token', token);
            return tokenIsExpired.pipe(
                map((isExpired) =>
                    isExpired && this.skipWhenExpired
                        ? request.clone()
                        : request.clone({
                            setHeaders: {
                                [this.headerName]: `${authScheme}${token}`,
                            },
                        })
                ),
                mergeMap((innerRequest) => next.handle(innerRequest))
            );
        }
        //console.log('no token');
        return next.handle(request);
    }

    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        if (!this.isAllowedDomain(request) || this.isDisallowedRoute(request)) {
            // console.log ('no allowed domain or disallowed route: ', request.url);
            return next.handle(request);
        }
        // console.log('allowed domain and allowed route: ', request.url);
        const token = this.tokenGetter(request);
        return fromPromiseOrValue(token).pipe(
            mergeMap((asyncToken: string | null) => {
                //console.log('asyncToken', asyncToken);
                return this.handleInterception(asyncToken, request, next);
            })
        );
    }
}