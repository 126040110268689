import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatToolbarModule } from '@angular/material/toolbar';
import { ApplicationToolbarContentComponent } from './components/application-toolbar-content/application-toolbar-content.component';
import { FooterComponent } from './components/footer/footer.component';
import { MainComponent } from './components/main/main.component';
import { BreakpointService } from './services/breakpoint.service';
import { CurrentRouteService } from './services/current-route.service';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    MainComponent,
    MatToolbarModule,
    FooterComponent,
    ApplicationToolbarContentComponent,
  ],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent {
  /**
   * Observable to check whether the scroll to top fab is visible or not
   */
  // public showScrollToTopFab$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);


  constructor(
    public readonly breakpointService: BreakpointService,
    public readonly currentRouteService: CurrentRouteService,
  ) { }


  // ngAfterViewInit(): void {
  //   // remove the teaser by ID from the DOM
  //   // adn enable the app component
  //   const teaser = document.getElementById('ystm-wallpaper');
  //   const appRoot = document.getElementById('app-root');
  //   if (teaser && appRoot) {
  //     teaser.remove();
  //     appRoot.classList.remove('display-none');
  //   }
  // }


  // /**
  //  * Method to steer the visibility of the scroll to top fab
  //  * - This implementation is using the IntersectionObserver API, which is Much, much mor efficient than scroll service or window visibility service
  //  * @param visibilityRatio is latest visibility ratio of the carousel outlet
  //  */
  // public onCarouselOutletVisible(visibilityRatio: number) {
  //   // console.log('onCarouselOutletVisible(), visibilityRatio: ', visibilityRatio);
  //   if (visibilityRatio > 0) {
  //     this.showScrollToTopFab$.next(false);
  //   } else {
  //     this.showScrollToTopFab$.next(true);
  //   }
  // }

  // public scrollToTop() {
  //   window.scrollTo({
  //     top: 0,
  //     behavior: 'smooth'
  //   });
  // }
}
