import { CommonModule } from '@angular/common';
import { Injector, ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { DebugConfig } from './models/debug-config.model';
import { LogService } from './services/log.service';

export let logService: LogService | null = null;


/**
 * debugging module providing
 * - log service as replacement for console logging features. Logging can be controlled by injection tokens
 * - silent log service as replacement for console logging features, but discarding any log
 */

@NgModule({
  imports: [
    CommonModule
  ]
})
export class DebugModule {

  constructor(
    private injector: Injector,
    @Optional() @SkipSelf() parentModule?: DebugModule
  ) {
    if (parentModule) {
      // throw new Error(
      //   'EbDebugModule is already loaded. Import it in the AppModule only.');
    } else {
      logService = this.injector.get(LogService);
    }

  }

  static forRoot(config?: DebugConfig): ModuleWithProviders<DebugModule> {
    return {
      ngModule: DebugModule,
      providers: [
        { provide: DebugConfig, useValue: config ?? new DebugConfig()},
      ]
    };
  }
}
